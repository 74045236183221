import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }
  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }}
}
