<div class="d-flex vh-100" [ngClass]="!!isCollapsed ? 'overflow-hidden' : ''">
    <div class="rounded-circle  d-flex justify-content-center align-items-center" *ngIf="!isCollapse"
        [ngClass]="!!isCollapsed ? 'collapsible-btn-small collapsible-btn' : 'collapsible-btn'">
        <button class="btn border-0" role="button" (click)="collapseSideBar()">
            <i class="bi bi-chevron-right text-white"></i>
        </button>
    </div>
    <div class="d-flex flex-column p-3" [ngClass]="!!isCollapsed ? 'side-nav-small' : 'side-nav'"
        [ngStyle]="{'background-color' : !!isLight ? '#041638' : '#171215'}">
        <a #dash (click)="navigateToDashboard(dash)" (contextmenu)="onRightClick('dashboard',dash)">
            <div class=" navbar-brand text-center pb-2" *ngIf="!isCollapsed" role="button">
                <img src="../../../assets/images/strellnew-dark.png" alt="" class="logo" />
            </div>
        </a>

        <div class=" navbar-brand text-center pb-2 my-1" *ngIf="!!isCollapsed" role="button">
            <a #dash (click)="navigateToDashboard(dash)" (contextmenu)="onRightClick('dashboard',dash)">
                <img src="../../../assets/images/strellnew-dark-single.png" alt="" class="logo" />
            </a>
        </div>
        <ul class="nav nav-pills flex-column mb-auto large-nav" *ngIf="!isCollapsed">
            <a #audit (click)="navigateToaudit(audit)" (contextmenu)="onRightClick('audits',audit)" [ngClass]="activeLink==='audit' ? 'active-link' : ''" class="d-flex text-white links">
                <img src="assets/images/dashboard/svg/audit-dashboard.svg" alt="" class="me-2" height="18"
                            width="18">
                            <span class="fs-16">Audit</span>
                <!-- <li class="nav-item">
                    <a iis class="nav-link text-white" [ngClass]="activeLink==='audit' ? 'active-link' : ''">
                        <img src="assets/images/dashboard/svg/audit-dashboard.svg" alt="" class="me-2" height="18"
                            width="18"> 
                        <span class="fs-16">Audit</span>
                    </a>
                </li> -->
            </a>
            <a #ce (click)="navigateToContentEditor(ce)"  style="width: 100%;white-space: nowrap;"  (contextmenu)="onRightClick('editors',ce)" [ngClass]="activeLink==='content-editor' ? 'active-link' : ''" class="d-flex text-white links">
                <img src="assets/images/dashboard/svg/content-writing-dashboard.svg" alt="" height="18"
                width="18" class="me-2">
                <span class="fs-16"> Content Editor</span>
                <!-- <li class="nav-item">
                    <a class="nav-link  text-white" [ngClass]="activeLink==='content-editor' ? 'active-link' : ''">
                        <img src="assets/images/dashboard/svg/content-writing-dashboard.svg" alt="" height="18"
                            width="18" class="me-2">
                        <span class="fs-16"> Content Editor</span>
                    </a>
                </li> -->
            </a>
            <!-- <a #lckr (click)="navigateTolckr(lckr)" (contextmenu)="onRightClick('keyword_finder',lckr)" [ngClass]="activeLink==='lckr' ? 'active-link' : ''" class="d-flex text-white links">
                <img src="assets/images/dashboard/svg/LCKR.svg" alt="" height="18" width="18" class="me-2">
                <span class="fs-16"> LCKR</span>
            </a> -->
            <a #lckr2 (click)="navigateTolckr2(lckr2)" (contextmenu)="onRightClick('keyword_finder2',lckr2)" [ngClass]="activeLink==='lckr2' ? 'active-link' : ''" class="d-flex text-white links">
                <img src="assets/images/dashboard/svg/LCKR.svg" alt="" height="18" width="18" class="me-2">
                <span class="fs-16"> LCKR</span>
            </a>
            <!-- <li class="nav-item">
                <a class="nav-link text-white">
                    <img src="assets/images/quick-learn.png" alt="" height="16" width="16" class="me-2">
                    <span class="fs-16">Quick Learn</span>
                </a>
            </li> -->
        </ul>
        <ul class="nav nav-pills flex-column mb-auto d-flex align-items-center collaps-nav" *ngIf="!!isCollapsed">
            <a #audit (click)="navigateToaudit(audit)" (contextmenu)="onRightClick('audits',audit)" [ngClass]="activeLink==='audit' ? 'active-link' : ''" class="d-flex" 
            matTooltip="Audit"
            matTooltipClass="example-tooltip-uppercase"
            matTooltipPosition="left">
                <img src="assets/images/dashboard/svg/audit-dashboard.svg" alt=""  height="18"
                width="18">
                <!-- <li class="nav-item">
                    <a class="nav-link text-white" [ngClass]="activeLink==='audit' ? 'active-link' : ''">
                        <img src="assets/images/dashboard/svg/audit-dashboard.svg" alt=""  height="18"
                            width="18">
                    </a>
                </li> -->
            </a>
            <a #ce (click)="navigateToContentEditor(ce)" (contextmenu)="onRightClick('editors',ce)" [ngClass]="activeLink==='content-editor' ? 'active-link' : ''" class="d-flex"
            matTooltip="Content Editor"
            matTooltipClass="example-tooltip-uppercase"
            matTooltipPosition="left">
                <img src="assets/images/dashboard/svg/content-writing-dashboard.svg" alt="" height="18"
                            width="18">
                <!-- <li class="nav-item">
                    <a class="nav-link  text-white" [ngClass]="activeLink==='content-editor' ? 'active-link' : ''">
                        <img src="assets/images/dashboard/svg/content-writing-dashboard.svg" alt="" height="18"
                            width="18">
                    </a>
                </li> -->
            </a>
            <!-- <a #lckr (click)="navigateTolckr(lckr)" (contextmenu)="onRightClick('keyword_finder',lckr)" [ngClass]="activeLink==='lckr' ? 'active-link' : ''" class="d-flex"
            matTooltip="LCKR"
            matTooltipClass="example-tooltip-uppercase"
            matTooltipPosition="left">
                <img src="assets/images/dashboard/svg/LCKR.svg" alt="" height="18" width="18">
            </a> -->
            <a #lckr2 (click)="navigateTolckr2(lckr2)"  (contextmenu)="onRightClick('keyword_finder2',lckr2)" [ngClass]="activeLink==='lckr2' ? 'active-link' : ''" class="d-flex"
            matTooltip="LCKR2"
            matTooltipClass="example-tooltip-uppercase"
            matTooltipPosition="left">
                <img src="assets/images/dashboard/svg/LCKR.svg" alt="" height="18" width="18">
            </a>
            <!-- <li class="nav-item">
                <a class="nav-link text-white">
                    <img src="assets/images/quick-learn.png" alt="" height="16" width="16" class="me-2">
                    <span class="fs-16">Quick Learn</span>
                </a>
            </li> -->
        </ul>
        <div class="box g-0 pt-3" [ngClass]="!isCollapsed ? 'row' : ''">
            <div [ngClass]="!isCollapsed ? 'd-flex align-items-center justify-content-between' : 'profile-div'">

                <div class="profile-info d-flex align-items-center">
                    <!-- <div class="aavtar position-relative" [matMenuTriggerFor]="menu" role="button"> -->
                    <!-- <img src="assets/images/avatar.png" alt="" class="rounded-circle"> -->
                    <div class="m-auto circle" [ngStyle]="{'background-color':  circleColor }"
                        #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (mouseover)="openMyMenu(menuTrigger)"
                        role="button">
                        <div class="initials">
                            {{ initials }}
                        </div>
                    </div>
                    <!-- <div class="small-box rounded-circle"></div> -->
                    <!-- </div> -->
                    <h2 class="mb-0 text-white fs-16 text-truncate" [ngClass]="!!isCollapsed ? 'd-none-txt-link' : ''">
                        {{userData?.first_name}}</h2>
                </div>

                <div class="theme-toggle">
                    <button
                        class="circle bg-orange d-flex justify-content-center my-2 align-items-center btn btn-primary"
                        role="button" #tooltip="matTooltip" matTooltip="Switch Theme" matTooltipClass='red-tooltip'
                        matTooltipPosition="right" aria-tooltip="Switch Theme" (click)="changeTheme()"
                        *ngIf="!!isLight">
                        <i class="bi bi-sun d-flex fs-14"></i>
                        <i class="bi bi-moon d-flex fs-14" (click)="changeTheme()" *ngIf="!isLight"></i>
                    </button>
                    <button
                        class="circle bg-orange d-flex justify-content-center my-2 align-items-center btn btn-primary"
                        role="button" #tooltip="matTooltip" matTooltip="Switch Theme" matTooltipClass='red-tooltip'
                        matTooltipPosition="right" aria-tooltip="Switch Theme" (click)="changeTheme()" *ngIf="!isLight">
                        <i class="bi bi-moon d-flex fs-14"></i>

                    </button>
                </div>

                <mat-menu #menu="matMenu">
                    <div class="nav-drop d-flex flex-column" (mouseleave)="menuTrigger.closeMenu()">
                        <a class="text-decoration-none Neutral menu-data mb-2" (click)="navigateToSumoCode()"><span
                                class="me-1">
                                <span class="dashboard-icons">
                                    <img src="assets/images/coupon-new.png" alt="" height="19" width="19">
                                </span>
                            </span> Apply Code</a>
                        <a class="text-decoration-none Neutral menu-data mb-2" (click)="navigateToTeam()"><span
                                class="m3-1">
                                <span class="dashboard-icons">
                                    <img src="assets/images/group-chat.png" alt="" height="24" width="26">
                                </span>
                            </span> Team</a>
                        <a class="text-decoration-none Neutral menu-data mb-2" (click)="navigateToSettings()"><span
                                class="me-1">
                                <span class="dashboard-icons">
                                    <img src="assets/images/setting.png" alt="" height="19" width="19">
                                </span>
                            </span> Settings</a>
                            <a class="text-decoration-none Neutral menu-data mb-2" (click)="navigateToPayments()"><span
                                class="me-1">
                                <span class="dashboard-icons">
                                    <i class="bi bi-wallet2 fs-6 me-1"></i>
                                    <!-- <img src="../../../assets/images/integration.jpg" alt="" height="19" width="19"> -->
                                </span>
                            </span>Upgrade</a>
                            <a class="text-decoration-none Neutral menu-data mb-2" (click)="navigateToIntegrations()"><span
                                class="me-1">
                                <span class="dashboard-icons">
                                    <img src="../../../assets/images/integration.jpg" alt="" height="19" width="19">
                                </span>
                            </span> Integrations</a>
                        <a (click)="logOut()" class="text-decoration-none Neutral menu-data v"><span class="me-1">
                            <span class="dashboard-icons">
                                <img src="assets/images/power.png" alt="" height="24" width="23">
                            </span>
                        </span> Logout</a>
                    </div>

                </mat-menu>
            </div>
        </div>
    </div>
</div>