<section class="page_404 ">
    <div class="container">
        <div class="row vh-100 overflow-hidden">
            <div class="col-sm-12 d-flex justify-content-center align-items-center">
                <div class="col-sm-10 col-sm-offset-1  text-center">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    </div>
                    <div class="contant_box_404">
                        <h3 class="h2">
                            Look like you're lost
                        </h3>
                        <p>the page you are looking for not avaible!</p>
                        <a href="" class="link_404" routerLink="/dashboard">Go to Dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>