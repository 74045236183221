<ng-container>
    <ng-container *ngIf="!isLoginOrRegister;then loggedInRoute;else loginRoute"></ng-container>
</ng-container>
<ng-template #loggedInRoute>
    <div class="d-flex vh-100">
        <app-sidebar (onCollapsed)="collapseTrigger($event)"></app-sidebar>
        <div class="" [ngClass]="!!isCollapse ? 'rt-side-content-small' : 'rt-side-content'">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-template>
<ng-template #loginRoute>
    <div class="w-100">
        <router-outlet></router-outlet>
    </div>
</ng-template>