import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { EditorService } from './shared/services/editor.service';
import { HttpService } from './shared/services/http.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { RegisterComponent } from './auth/register/register.component';
import { AppsumoRegisterComponent } from './auth/appsumo-register/appsumo-register.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ResetComponent } from './auth/reset/reset.component';
import { PaymentsComponent } from './shared/payments/payments.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { GoogleSigninButtonDirective } from '@abacritt/angularx-social-login';



const config: SocketIoConfig = { url: 'https://socket.strell.io/', options: {} };


@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    AppsumoRegisterComponent,
    LoginComponent,
    ForgotComponent,
    ResetComponent,
    PaymentsComponent,
  ],
  imports: [
    NgbCollapseModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatIconModule,
    ScrollingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SocketIoModule.forRoot(config),
    SocialLoginModule,
    // SocialUser

  ],
  providers: [EditorService,HttpService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "1066248667795-v8jjep9hvs4i9ttmcdnbjvj9ecb1un9h.apps.googleusercontent.com"
            )
          },
        ],
        onError: (err:any) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    GoogleSigninButtonDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
