import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorService } from '../../shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  uid: any = ''

  registerForm: FormGroup = this.formBuilder.group({})
  isSubmitted: boolean = false;
  loading: boolean = false;
  href:string = "";
  constructor(
    private formBuilder: FormBuilder,
    private editorServ: EditorService,
    private toster: ToastrService,
    private cookieServ: CookieService,
    private router: Router,
    private route:ActivatedRoute,
    private socialAuth:SocialAuthService
  ) {
    this.socialAuth.authState.subscribe((user) => {
      if(user && user!==null){
        localStorage.setItem('auth_token',user.idToken);
        this.editorServ.storegoogleAuth({access_token:user.idToken}).subscribe((res:any)=>{
          if(!!res && res.tokens && res.user_details){
            localStorage.setItem('auth_token',res.tokens.access);
            this.cookieServ.set('uid', res.user_details.uid );
            this.cookieServ.set('userName', res.user_details.first_name );
            this.cookieServ.set('email', res.user_details.email );
            this.cookieServ.set('plan', res.user_details.subscription_name );
            // this.toster.success("User login successfully")
            this.router.navigate([`dashboard`])
          }
        },(err:any)=>{
          this.toster.error(err.error.message)
        });
      }
    });
    this.uid = this.cookieServ.get("uid");
    if (this.uid && localStorage.getItem('auth_token')) {
      this.router.navigate([`dashboard`])
    }
    this.route.queryParams.subscribe(params => {
      this.href = params['href'];
  });

  }

  ngOnInit(): void {
    this.initForm();
  }

    initForm(){
      this.registerForm = this.formBuilder.group({
          firstName:["",[Validators.required]],
          lastName:["",[Validators.required]],
          // email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],          
          email: ['', [Validators.required, Validators.email,Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],          
          password:["",[Validators.required]],
          confirmPassword:["",[Validators.required]],
      },{ validators: this.checkPasswords })
    }

  submitForm() {
    this.isSubmitted = true;
    if (this.registerForm.valid) {
      let Obj:any = {
        "first_name": this.registerForm.controls["firstName"].value,
        "last_name": this.registerForm.controls["lastName"].value,
        "email": this.registerForm.controls["email"].value.toLowerCase(),
        "password": this.registerForm.controls["password"].value,
        // "confirm_password": this.registerForm.controls["confirmPassword"].value,
      }
      if(this.href!==""){
        Obj['href'] = this.href;
      }
      this.loading = true;
      this.editorServ.registration(Obj).subscribe((res: any) => {
        try {
          if (res.message) {
            this.toster.success(res.message)
            this.registerForm.reset();
            this.loading = false;
          }
        } catch (error: any) {
          this.toster.error(error.message);
          this.loading = false;
        }
      },(err)=>{
        this.loading = false;
      });
      this.isSubmitted = false;
      // API call for register
    }
  }
  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }
}
