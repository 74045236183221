import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ThemingService } from './shared/services/theming.service';
import { Meta } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { EditorService } from './shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'demo-pro';
  theme: boolean = true;
  // loading: boolean = true;
  uid: any = ''
  erroMessage : any = ''
  successMessage : any = ''
  nightModeButton: boolean = false
  isCollapse: any;
  isLoginOrRegister: boolean = true;
  paymentRoute:string =""
  constructor(private themingService: ThemingService, private router: Router, private route: ActivatedRoute,
    private readonly meta: Meta, 
    private cookieServ: CookieService,
    private editorServie: EditorService,
    private toster: ToastrService,
  ) {
    this.uid = this.cookieServ.get("uid");
    if (this.uid) {
      this.nightModeButton = true
    } else {
      this.nightModeButton = false
    }
    let x = this.route.queryParams.subscribe((params: any) => {
      this.erroMessage = params?.error ? params?.error : "";
      this.successMessage = params?.success ? params?.success : "";
    });

    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.uid = this.cookieServ.get("uid");
        let routeName = event.url.split('/')[1];
        routeName = routeName.split("?")[0];
        let urlAfterRedirects  = event?.urlAfterRedirects 
        if (urlAfterRedirects == '/login' || routeName === '' || !this.uid || routeName === 'appsumo' ||routeName === 'login' || routeName === 'register' || routeName  == "forgot" || routeName  ==  "reset" || this.erroMessage != "" || this.successMessage != "" ) {
          this.isLoginOrRegister = true;
        }else{
          this.isLoginOrRegister = false
        }
      } 
    });
    //  let routeName =  this.router.url.split('/')[0];
    //  debugger
    //  if(routeName==='login' || routeName==='register'){
    //   this.isLoginOrRegister = true;
    //  }

    // router.events.subscribe((routerEvent: any) => {
    //   this.checkRouterEvent(routerEvent);
    // });
    this.route.queryParams.subscribe(params => {
      let _theme = params['theme'];
      if (_theme == "false") {
        this.theme = false
      } else {
        this.theme = true
      }
    })
  }
  // @HostListener('window:resize', ['$event'])
  // onResize() {

  // }
  ngOnInit() {
    if (window.innerWidth <= 700) {
      this.meta.updateTag({ name: 'viewport', content: 'width=device-width,initial-scale=0.4' })
    }
    else if (window.innerWidth <= 1100) {
      this.meta.updateTag({ name: 'viewport', content: 'width=device-width,initial-scale=0.4' })
    }
    this.loadScript('./../../../assets/gleap.js');
  }
  loadScript(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  
  checkRouterEvent(routerEvent: any): void {
    if (routerEvent instanceof NavigationStart) {
      // this.loading = true;      
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      // this.loading = false;
    }
  }
  setTheme() {
    // this.themingService.SetName(this.theme);
    this.themingService.setThemeName(this.theme);
  }
  collapseTrigger(event: any) {
      this.isCollapse = event;
  }

  signOut(): void {
    const logoutLink = document.createElement('a');
    logoutLink.href = 'https://accounts.google.com/logout';
    logoutLink.target = '_blank';
    document.body.appendChild(logoutLink);
    logoutLink.click();
    document.body.removeChild(logoutLink);
  }
}
