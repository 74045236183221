import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorService } from '../../shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  activateKey: any = ''
  isSubmitted:boolean = false;
  resetPassForm!: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private editorServ: EditorService,
    private toster: ToastrService,
    private cookieServ: CookieService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    let x = this.activatedRoute
      .params
      .subscribe((params: any) => {
        this.activateKey = params?.id ? params?.id : ""
      });
  }

  ngOnInit(): void {

    this.resetPassForm = this.formBuilder.group({
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    }, { validators: this.checkPasswords })

  }

  submitForm(){
    this.isSubmitted = true;
    if(this.resetPassForm.valid){
      let Obj = {
        "new_password": this.resetPassForm.controls["password"].value,
        "otp":this.activateKey
    }
      this.editorServ.updateForgotPass(Obj).subscribe((res:any)=>{
        try {
          if(res.status.toString() == 'false'){
            this.toster.error(res.message)
          }
          if(res.status.toString() == 'true'){
            this.toster.success(res.message)
            localStorage.clear()
            sessionStorage.clear()
            this.cookieServ.delete("uid","/",window.location.hostname,true,"None");
            this.router.navigate(['login']);
          }
        } catch (error:any) {
          this.toster.error(error.message)
        }
      });
      this.isSubmitted = false;
    }
  }


  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }


}
