import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect-component',
  templateUrl: './redirect-component.component.html',
  styleUrls: ['./redirect-component.component.scss']
})
export class RedirectComponentComponent implements OnInit {
  sub: any;
  status!: any
  constructor(private route: ActivatedRoute, private router: Router) {
    this.sub = this.route
      .data
      .subscribe(v => { this.status = v.payment_status });
    this.router.navigate(['/dashboard'], { queryParams: { payment_id: this.status } })
  }

  ngOnInit(): void {
  }

}
