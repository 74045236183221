<div class="pages-wrapper">
    <div class="inner-banner-section bottom-padding-200px pricing">
      <div class="faq-pricing">
        <div class="inner-banner-wrapper">
          <div class="inner-banner-contant">
            <div>
              <h1 class="section-title">Simplify Organic Growth Today</h1>
            </div>
            <div class="breadcrumb-wrapper">
              <a href="#" class="single-breadcrumb-link w-inline-block">
                <div>Home</div>
              </a>
              <div class="breadcrumb-arrow">&gt;</div>
              <a href="pricing.html" aria-current="page" class="single-breadcrumb-link w-inline-block w--current">
                <div>Pricing</div>
              </a>
            </div>
          </div><img src="images/inner-banner-shape-1.svg" loading="lazy" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"  alt="" class="inner-banner-shape _1"><img src="images/inner-banner-shape-2.svg" loading="lazy" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" alt="" class="inner-banner-shape _2">
        </div>
      </div>
    </div>
    <div class="section-bg natural-color-300">
      <div class="pricing-plan-section mg-bottom-100px">
        <div class="faq-pricing">
          <div class="pricing-plan-wrapper">
            <div class="pricing-plan-tabs-wrapper w-tabs">
              <div style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;" class="pricing-plan-tabs-menu w-tab-menu">
                <!-- <a class="plan-pricing-tab-link w-inline-block w-tab-link" (click)="activeLink='Monthly'" [ngClass]="activeLink==='Monthly' ? 'w--current' : ''">
                  <div>Monthly Billing</div>
                </a> -->
                <a class="plan-pricing-tab-link w-tab-link"  (click)="activeLink='Yearly'" [ngClass]="activeLink==='Yearly' ? 'w--current' : ''">
                  <div>Annually Billing</div>
                </a>
              </div>
              <div class="pricing-plan-tabs-contant w-tab-content">
                <ng-container *ngIf="activeLink==='Monthly'">
                  <div data-w-tab="Tab 1" class="pricing-plan-tab-pane w-tab-pane" [ngClass]="activeLink==='Monthly' ? 'w--tab-active' : ''">
                    <div class="pricing-plan-tabs-contant-wrapper">
                      <div class="pricing-plan-flex my-5">
                        <ng-container  *ngIf="region==='India'">
                          <div class="collection-list-wrapper w-dyn-list" *ngFor="let data of paymentDetails;let i = index">
                            <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                <div class="pricing-plan-single-card" [ngClass]="i===2 ? '_2' : ''">
                                  <div class="pricing-plan-top-wrap">
                                    <h3 class="pricing-plan-card-name" [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name}}</h3>
                                    <div class="plan-price-wrapper">
                                      <div class="plan-price mon no" [ngClass]="i===2 ? 'text-light' : ''">${{data.price_monthly_india_digit}}</div>
                                      <div class="pricing-plan-arrow" [ngClass]="i===2 ? 'text-light' : ''">/</div>
                                      <div class="pricing-plan-date-text" [ngClass]="i===2 ? 'text-light' : ''">Monthly</div>
                                    </div>
                                    <p class="pricing-plan-details" [ngClass]="i===2 ? 'text-light' : ''">{{data.description}}</p>
                                  </div>
                                  <div class="pricing-plan-border"></div>
                                  <div class="pricing-plan-bottom-wrap">
                                    <div class="pricing-plan-facility-wrap">
                                      <div class="pricing-plan-facility-title-wrap">
                                        <h4 class="pricing-plan-facility-title" [ngClass]="i===2 ? 'text-light' : ''">What’s Included?</h4>
                                      </div>
                                      <div class="pricing-facility-contant new" *ngIf="i!==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hide">
                                          <div class="pricing-plan-facility-text">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1 hide"></div><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hide">
                                          <div class="pricing-plan-facility-text"></div>
                                        </div>
                                      </div>
                                      <div class="pricing-facility-contant pro" *ngIf="i==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div>
                                  <a (click)="getSubscribe(data)" [ngClass]="i===2 ? 'get-started-button w-inline-block w-100' : 'inner-button _100 w-inline-block'">
                                    <div class="inner-button-text">Subscribe</div>
                                    <!-- <div class="inner-button-hover left"></div>
                                    <div class="inner-button-hover right"></div> -->
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="region==='Global'">
                          <div class="collection-list-wrapper w-dyn-list" *ngFor="let data of paymentDetails;let i = index">
                            <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                              <div role="listitem" class="w-dyn-item" >
                                <div class="pricing-plan-single-card" [ngClass]="i===2 ? '_2' : ''">
                                  <div class="pricing-plan-top-wrap">
                                    <h3 class="pricing-plan-card-name" [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name}}</h3>
                                    <div class="plan-price-wrapper">
                                      <div class="plan-price mon no" [ngClass]="i===2 ? 'text-light' : ''">${{data.price_monthly_global_digit}}</div>
                                      <div class="pricing-plan-arrow" [ngClass]="i===2 ? 'text-light' : ''">/</div>
                                      <div class="pricing-plan-date-text" [ngClass]="i===2 ? 'text-light' : ''">Monthly</div>
                                    </div>
                                    <p class="pricing-plan-details"[ngClass]="i===2 ? 'text-light' : ''">{{data.description}}</p>
                                  </div>
                                  <div class="pricing-plan-border"></div>
                                  <div class="pricing-plan-bottom-wrap">
                                    <div class="pricing-plan-facility-wrap">
                                      <div class="pricing-plan-facility-title-wrap">
                                        <h4 class="pricing-plan-facility-title" [ngClass]="i===2 ? 'text-light' : ''">What’s Included?</h4>
                                      </div>
                                      <div class="pricing-facility-contant new" *ngIf="i!==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hide">
                                          <div class="pricing-plan-facility-text">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                      <div class="pricing-facility-contant pro" *ngIf="i==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div>
                                  <a  (click)="getSubscribe(data)" [ngClass]="i===2 ? 'get-started-button w-inline-block w-100' : 'inner-button _100 w-inline-block'">
                                    <div class="inner-button-text new-1">Subscribe</div>
                                    <!-- <div class="inner-button-hover left"></div>
                                    <div class="inner-button-hover right"></div> -->
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <!-- <div class="collection-list-wrapper _3 w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card new-3">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name">Basic</h3>
                                  <div class="plan-price-wrapper">
                                    <div class="plan-price mon no">$49.99</div>
                                    <div class="pricing-plan-arrow">/</div>
                                    <div class="pricing-plan-date-text">Monthly</div>
                                  </div>
                                  <p class="pricing-plan-details">More power for small teams who want better collaboration</p>
                                </div>
                                <div class="pricing-plan-border"></div>
                                <div class="pricing-plan-bottom-wrap">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">120 Audit/ Editors Credits/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">3 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">80 Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">25000 Keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a href="https://buy.stripe.com/6oE14Bfq2aI3eTC6oF"  target="_blank" class="inner-button _100 w-inline-block">
                                      <div class="inner-button-text">Subscribe</div>
                                      <div class="inner-button-hover left"></div>
                                      <div class="inner-button-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="collection-list-wrapper _2 w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card _2">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name natural-color-100">Pro</h3>
                                  <div class="plan-price-wrapper">
                                    <div class="plan-price natural-color-100 mon whito no">$89.99</div>
                                    <div class="pricing-plan-arrow natural-color-100">/</div>
                                    <div class="pricing-plan-date-text natural-color-100">Monthly</div>
                                  </div>
                                  <p class="pricing-plan-details natural-color-100">Scale your business, increase productivity and keep your teams connected</p>
                                </div>
                                <div class="pricing-plan-border natural-color-100"></div>
                                <div class="pricing-plan-bottom-wrap new1">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title natural-color-100">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant pro">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">250 Audit/ Editors Credits/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">10 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">200 Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">60000 Keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a  target="_blank" href="https://buy.stripe.com/8wM7sZ91EcQbdPy7sK" class="get-started-button w-inline-block">
                                      <div class="get-started-text new-1">Subscribe</div>
                                      <div style="-webkit-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="get-started-hover left"></div>
                                      <div style="-webkit-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="get-started-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="collection-list-wrapper w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card new-3">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name">Growth</h3>
                                  <div class="plan-price-wrapper">
                                    <div class="plan-price mon no">$129.99</div>
                                    <div class="pricing-plan-arrow">/</div>
                                    <div class="pricing-plan-date-text">Monthly</div>
                                  </div>
                                  <p class="pricing-plan-details">Strell for the most demanding enterprises</p>
                                </div>
                                <div class="pricing-plan-border"></div>
                                <div class="pricing-plan-bottom-wrap">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant last">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">Unlimited Audit/ Editors/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">20 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">Unlimited Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">100000 keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a href="https://buy.stripe.com/aEU7sZ3Hk17teTCcN5" target="_blank" class="inner-button _100 w-inline-block">
                                      <div class="inner-button-text">Subscribe</div>
                                      <div class="inner-button-hover left"></div>
                                      <div class="inner-button-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <!-- <div class="discount-offer">
                        <div class="_50-discount">
                          <div class="text-block-18">40% OFF on yearly plans</div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="activeLink==='Yearly'">
                  <div data-w-tab="Tab 2" class="pricing-plan-tab-pane w-tab-pane" [ngClass]="activeLink==='Yearly' ? 'w--tab-active' : ''">
                    <div class="pricing-plan-tabs-contant-wrapper">
                      <div class="pricing-plan-flex  my-5">
                        <ng-container *ngIf="region==='India'">
                          <div class="collection-list-wrapper _22 w-dyn-list" *ngFor="let data of paymentDetails;let i = index">
                            <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                <div class="pricing-plan-single-card" [ngClass]="i===2 ? '_2' : 'new3'">
                                  <div class="pricing-plan-top-wrap">
                                    <h3 class="pricing-plan-card-name"  [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name}}</h3>
                                    <h1 class="heading-7"  [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name==='Gold' ? '$129/yr' : '$249/yr'}}</h1>
                                    <div class="plan-price-wrapper">
                                      <!-- <div class="div-block-12">
                                        <div class="plan-price"  [ngClass]="i===2 ? 'text-light' : ''">${{data.price_anually_india_digit}}</div>
                                      </div> -->
                                      <!-- <div class="pricing-plan-arrow new"  [ngClass]="i===2 ? 'text-light' : ''">/</div>
                                      <div class="pricing-plan-date-text"  [ngClass]="i===2 ? 'text-light' : ''">Annually</div> -->
                                    </div>
                                    <p class="pricing-plan-details"  [ngClass]="i===2 ? 'text-light' : ''">{{data.description}}
                                    </p>
                                  </div>
                                  <div class="pricing-plan-border"></div>
                                  <div class="pricing-plan-bottom-wrap">
                                    <div class="pricing-plan-facility-wrap">
                                      <div class="pricing-plan-facility-title-wrap">
                                        <h4 class="pricing-plan-facility-title"  [ngClass]="i===2 ? 'text-light' : ''">What’s Included?</h4>
                                      </div>
                                      <div class="pricing-facility-contant new" *ngIf="i!==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy" src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex hidden">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex hidden">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                      <div class="pricing-facility-contant pro" *ngIf="i==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <a (click)="getSubscribePabbly(data)" cl [ngClass]="i===2 ? 'get-started-button w-inline-block w-100 mx-2' : 'inner-button _100 w-inline-block'">
                                    <div class="inner-button-text">Subscribe</div>
                                    <!-- <div class="inner-button-hover left"></div>
                                    <div class="inner-button-hover right"></div> -->
                                  </a>
                                  <!-- <a (click)="getSubscribePabbly(data)" [ngClass]="i===2 ? 'get-started-button w-inline-block w-100' : 'inner-button _100 w-inline-block'" style="bottom: 10%;">
                                    <div class="inner-button-text">Pabbly</div>
                                  </a> -->
                                </div>
                              </div>
                            </div>
                            <!-- <div class="w-dyn-empty">
                              <div>No items found.</div>
                            </div> -->
                          </div>
                        </ng-container>
                        <ng-container *ngIf="region==='Global'">
                          <div class="collection-list-wrapper _22 w-dyn-list" *ngFor="let data of paymentDetails;let i = index">
                            <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                <div class="pricing-plan-single-card" [ngClass]="i===2 ? '_2' : 'new3'">
                                  <div class="pricing-plan-top-wrap">
                                    <h3 class="pricing-plan-card-name"  [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name}}</h3>
                                    <h1 class="heading-7"  [ngClass]="i===2 ? 'text-light' : ''">{{data.plan_name==='Gold' ? '$129/yr' : '$249/yr'}}</h1>
                                    <div class="plan-price-wrapper">
                                      <!-- <div class="div-block-12">
                                        <div class="plan-price"  [ngClass]="i===2 ? 'text-light' : ''">${{data.price_anually_global_digit}}</div>
                                      </div>
                                      <div class="pricing-plan-arrow new"  [ngClass]="i===2 ? 'text-light' : ''">/</div>
                                      <div class="pricing-plan-date-text"  [ngClass]="i===2 ? 'text-light' : ''">Annually</div> -->
                                    </div>
                                    <p class="pricing-plan-details"  [ngClass]="i===2 ? 'text-light' : ''">{{data.description}}
                                    </p>
                                  </div>
                                  <div class="pricing-plan-border"></div>
                                  <div class="pricing-plan-bottom-wrap">
                                    <div class="pricing-plan-facility-wrap">
                                      <div class="pricing-plan-facility-title-wrap">
                                        <h4 class="pricing-plan-facility-title"  [ngClass]="i===2 ? 'text-light' : ''">What’s Included?</h4>
                                      </div>
                                      <div class="pricing-facility-contant new" *ngIf="i!==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy" src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex hidden">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex hidden">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon hidden1"></div>
                                          <div class="pricing-plan-facility-text">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                      <div class="pricing-facility-contant pro" *ngIf="i==2">
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.audit_content_editor}} Audit/ Editors Credits/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex" *ngIf="!!data.nlp">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.team_member}} Team Members</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_report}} Keyword Reports/mo</div>
                                        </div>
                                        <div class="pricing-facility-single-wrap flex">
                                          <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                          <div class="pricing-plan-facility-text natural-color-100">{{data.kw_analysis}} Keyword SERP Analysis/mo</div>
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </div>
                                  <a (click)="getSubscribePabbly(data)" [ngClass]="i===2 ? 'get-started-button w-inline-block w-100' : 'inner-button _100 w-inline-block'">
                                    <div class="inner-button-text">Subscribe</div>
                                    <!-- <div class="inner-button-hover left"></div>
                                    <div class="inner-button-hover right"></div> -->
                                  </a>
                                </div>
                                <!-- <a (click)="getSubscribePabbly(data)" [ngClass]="i===2 ? 'get-started-button w-inline-block w-100' : 'inner-button _100 w-inline-block'" style="bottom: 10%;">
                                  <div class="inner-button-text">Pabbly</div>
                                </a> -->
                              </div>
                            </div>
                            <!-- <div class="w-dyn-empty">
                              <div>No items found.</div>
                            </div> -->
                          </div>
                          <!-- <img src="../../../assets/images/strellnew1.png"> -->
                        </ng-container>
                        <!-- <div class="collection-list-wrapper w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name">Basic</h3>
                                  <h1 class="mon">$24.99</h1>
                                  <div class="plan-price-wrapper">
                                    <div class="div-block-12">
                                      <div class="plan-price before">$499.88</div>
                                      <div class="plan-price">$299.94</div>
                                    </div>
                                    <div class="pricing-plan-arrow new">/</div>
                                    <div class="pricing-plan-date-text">Annually</div>
                                  </div>
                                  <p class="pricing-plan-details">More power for small teams who want better collaboration</p>
                                </div>
                                <div class="pricing-plan-border"></div>
                                <div class="pricing-plan-bottom-wrap">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">120 Audit/ Editors Credits/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">3 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">80 Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">25000 Keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a href="https://buy.stripe.com/7sI4gN1zc3fBdPydRb" target="_blank" class="inner-button _100 w-inline-block">
                                      <div class="inner-button-text">Subscribe</div>
                                      <div class="inner-button-hover left"></div>
                                      <div class="inner-button-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="collection-list-wrapper _2 w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card _2">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name natural-color-100">Pro</h3>
                                  <h1 class="new-price change">$44.99</h1>
                                  <div class="plan-price-wrapper">
                                    <div class="div-block-11">
                                      <div class="plan-price natural-color-100 before">$1079.88</div>
                                      <div class="plan-price natural-color-100">$539.94</div>
                                    </div>
                                    <div class="pricing-plan-arrow natural-color-100 new whit">/</div>
                                    <div class="pricing-plan-date-text natural-color-100">Annually</div>
                                  </div>
                                  <p class="pricing-plan-details natural-color-100">Scale your business, increase productivity and keep your teams connected</p>
                                </div>
                                <div class="pricing-plan-border natural-color-100"></div>
                                <div class="pricing-plan-bottom-wrap">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title natural-color-100">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant pro">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">250 Audit/ Editors Credits/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">10 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">200 Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkimagewhite.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text natural-color-100">60000 Keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a href="https://buy.stripe.com/28obJf91E3fBcLu28u" target="_blank" class="get-started-button w-inline-block">
                                      <div style="color:rgb(0,0,0)" class="get-started-text">Subscribe</div>
                                      <div style="-webkit-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, -58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="get-started-hover left"></div>
                                      <div style="-webkit-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 58px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="get-started-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="collection-list-wrapper w-dyn-list">
                          <div role="list" class="pricing-plan-tabs-grid w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                              <div class="pricing-plan-single-card new-3">
                                <div class="pricing-plan-top-wrap">
                                  <h3 class="pricing-plan-card-name">Growth</h3>
                                  <h1 class="heading-6">$64.99</h1>
                                  <div class="plan-price-wrapper">
                                    <div class="div-block-12">
                                      <div class="plan-price before">$1559.88</div>
                                      <div class="plan-price">$799.94</div>
                                    </div>
                                    <div class="pricing-plan-arrow new">/</div>
                                    <div class="pricing-plan-date-text">Annually</div>
                                  </div>
                                  <p class="pricing-plan-details">Strell for the most demanding enterprises</p>
                                </div>
                                <div class="pricing-plan-border"></div>
                                <div class="pricing-plan-bottom-wrap">
                                  <div class="pricing-plan-facility-wrap">
                                    <div class="pricing-plan-facility-title-wrap">
                                      <h4 class="pricing-plan-facility-title">What’s Included?</h4>
                                    </div>
                                    <div class="pricing-facility-contant last">
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">Unlimited Audit/ Editors/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">NLP Support</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">20 Team Members</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">Unlimited Keyword Reports/mo</div>
                                      </div>
                                      <div class="pricing-facility-single-wrap flex">
                                        <div class="pricing-facility-icon-wrap"><img loading="lazy"  src="../../../assets/images/checkicon.svg" alt="" class="pricing-plan-facility-icon"></div>
                                        <div class="pricing-plan-facility-text">100000 keyword SERP Analysis/mo</div>
                                      </div>
                                    </div>
                                    <a href="https://buy.stripe.com/28odRn1zc5nJ3aU14r" target="_blank" class="inner-button _100 last w-inline-block">
                                      <div class="inner-button-text">Subscribe</div>
                                      <div class="inner-button-hover left"></div>
                                      <div class="inner-button-hover right"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="discount-offer">
                        <div class="_50-discount">
                          <div class="text-block-18">40% OFF on yearly plans</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>