import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { EditorService } from '../services/editor.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ThemingService } from '../services/theming.service';
import Gleap from 'gleap';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SidebarComponent implements OnInit, OnChanges {
  uid: string = ""
  @Output() onCollapsed = new EventEmitter<any>();
  isCollapse: any;
  isCollapsed: boolean = false;
  activeLink: string = "dashboard"
  userData: any = {}
  circleColor: string = "";
  initials: string = "";
  private colors = [
    '#EB7181', // red
    '#468547', // green
    '#FFD558', // yellow
    '#3670B2', // blue
  ];
  timedOutCloser: any;
  isLight: boolean = true
  id: any = '';
  showNav: boolean = false;
  constructor(private editorServie: EditorService,
    private router: Router,
    private cookieServ: CookieService,
    private themingService: ThemingService,
    private socialAuth:SocialAuthService,
    private activeRoute: ActivatedRoute) {
    this.themingService.getId().subscribe(res => {
      this.id = res;
      if (this.id !== '' && this.id !== undefined) {
        this.isCollapse = true;
        this.isCollapsed = true;
        this.onCollapsed.emit(this.isCollapsed);
      } else {
        this.isCollapse = false;
        this.isCollapsed = false
        this.onCollapsed.emit(this.isCollapsed);
      }
    });
    let route = this.router.url;
    if (route === "/payments") {
      this.isCollapse = true;
      this.isCollapsed = true;
      this.onCollapsed.emit(this.isCollapsed);
    }
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let routeName = event.url.split('/')[3];
        let routeName1 = event.url.split('/')[2];
        this.id = event.url.split('/')[4];
        if (routeName === 'audits'|| routeName === 'audit') {
          this.activeLink = "audit"; 
        }else if (routeName === 'keyword_finder2' || routeName === 'keyword_finder' || routeName === 'dashboard2') {
          this.activeLink = "lckr2";
        } else if (routeName === 'editors'|| routeName === 'editor') {
          this.activeLink = "content-editor"
        }else{
          this.activeLink="dashboard"
        }
        if ((this.id !== '' && this.id !== undefined) || routeName1==="payments") {
          this.isCollapse = true;
          this.isCollapsed = true;
          if(routeName1==="payments"){
            this.activeLink="dashboard";
          }
          this.onCollapsed.emit(this.isCollapsed);
        } else {
          this.isCollapse = false;
          this.isCollapsed = false
          this.onCollapsed.emit(this.isCollapsed);
        }
      }
    });
    // this.activedRoute();
  }

  ngOnInit(): void {
   
    this.activedRoute();

    this.uid = this.cookieServ.get("uid");
    this.onCollapsed.emit(this.isCollapsed);
    if (!!this.uid && !!localStorage.getItem('auth_token')) {
      this.getUserProfile();
    }
    this.themingService.setThemeName(this.isLight)
  }
  activedRoute(){
    if (this.router.url) {
      let routeName = this.router.url.split('/')[3];
      let routeName1 = this.router.url.split('/')[2];
      this.id = this.router.url.split('/')[4];
      if (routeName === 'audits' || routeName === 'audit') {
        this.activeLink = "audit";
      }else if (routeName === 'keyword_finder2' || routeName === 'keyword_finder' || routeName === 'dashboard2') {
        this.activeLink = "lckr2";
      } else if (routeName === 'editors' || routeName === 'editor') {
        this.activeLink = "content-editor"
      }else{
        this.activeLink="dashboard"
      }
      if ((this.id !== '' && this.id !== undefined) || routeName1==="payments") {
        this.isCollapse = true;
        this.isCollapsed = true;
        if(routeName1==="payments"){
          this.activeLink="dashboard";
        }
        this.onCollapsed.emit(this.isCollapsed);
      } else {
        this.isCollapse = false;
        this.isCollapsed = false
        this.onCollapsed.emit(this.isCollapsed);
      }
    }
  }
  ngOnChanges() {
    this.isCollapsed = this.isCollapse;
  }
  getUserProfile() {
    this.editorServie.getUserprofile({ uid: this.uid }).subscribe(res => {
      this.userData = res.userDetails[0];
      const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomIndex];
      this.createInititals();
    })

  }
  createInititals(): void {
    this.initials = this.userData?.first_name.concat(" ", this.userData?.last_name)
      .split(" ")
      .map((n: any) => n[0])
      .join("").toUpperCase();
  }
  navigateToDashboard(ele?: any) {
    this.activeLink = "dashboard";
    ele.removeAttribute('href');
    ele.removeAttribute('target')
    this.router.navigate(['dashboard']);
  }
  navigateToSettings() {
    this.activeLink = "dashboard";
    this.router.navigate(['dashboard/settings']);
  }
  navigateToIntegrations() {
    this.activeLink = "dashboard";
    this.router.navigate(['dashboard/integration']);
  }
  navigateToPayments(){
    this.activeLink = "dashboard";
    this.router.navigate(['payments']);
  }
  navigateToaudit(ele?: any) {
    this.activeLink = "audit";
    ele.removeAttribute('href');
    ele.removeAttribute('target')
    this.router.navigate(['dashboard/audit-report/audits']);
  }
  navigateTolckr(ele?: any) {
    this.activeLink = "lckr";
    ele.removeAttribute('href');
    ele.removeAttribute('target')
    this.router.navigate(['dashboard/keyword_finder']);
  }
  navigateTolckr2(ele?: any) {
    this.activeLink = "lckr2";
    ele.removeAttribute('href');
    ele.removeAttribute('target')
    this.router.navigate(['dashboard/lckr/keyword_finder2']);
  }
  navigateToContentEditor(ele?: any) {
    this.activeLink = "content-editor";
    ele.removeAttribute('href');
    ele.removeAttribute('target')
    this.router.navigate(['dashboard/content-editor/editors']);
  }
  navigateToSumoCode() {
    this.activeLink = "dashboard";
    this.router.navigate(['dashboard/code']);
  }
  navigateToTeam() {
    this.activeLink = "dashboard";
    this.router.navigate(['dashboard/team']);
  }
  // logOut(){
  //   localStorage.clear()
  //   sessionStorage.clear()
  //   this.cookieServ.delete("uid","/",window.location.hostname,true,"None");
  //   this.router.navigate(['login']);
  // }
  // collapseSideBar(){
  //   this.isCollapsed =! this.isCollapsed;
  // }
  logOut() {
    Gleap.clearIdentity();
    localStorage.clear();
    this.socialAuth.signOut();
    sessionStorage.clear()
    this.cookieServ.delete("uid", "/", window.location.hostname, true, "None");
    this.cookieServ.delete("userName", "/", window.location.hostname, true, "None");
    this.cookieServ.delete("email", "/", window.location.hostname, true, "None");
    this.cookieServ.delete("plan", "/", window.location.hostname, true, "None");
    this.cookieServ.delete("first_name", "/", window.location.hostname, true, "None");
    this.cookieServ.delete("last_name", "/", window.location.hostname, true, "None");
    this.router.navigate(['login']);
  }

  collapseSideBar() {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      let id;
      clearTimeout(id)
      id = setTimeout(() => {
        this.showNav = true;
      }, 100);
    }
    this.onCollapsed.emit(this.isCollapsed);
  }
  openMyMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.openMenu();
  }
  closeMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }
  changeTheme() {
    this.isLight = !this.isLight;
    this.themingService.setThemeName(this.isLight)
  }
  onRightClick(arg: string, ele?: any) {
     if (arg == 'audits') {
      let data = ele;
      data.href = this.router.createUrlTree([`dashboard/audit-report/audits`]);
      data.target = "_blank"
    }else if (arg == 'editors') {
      let data = ele;
      data.href = this.router.createUrlTree([`dashboard/content-editor/editors`]);
      data.target = "_blank"
    }else if (arg == "keyword_finder2") {
      let data = ele;
      data.href = this.router.createUrlTree([`dashboard/lckr/keyword_finder2`]);
      data.target = "_blank"
    } else if (arg === 'dashboard') {
      let data = ele;
      data.href = this.router.createUrlTree(['dashboard']);
      data.target = "_blank"
    }
    // if(arg!=='dashboard'){
    //   const url = this.router.createUrlTree([`dashboard/${arg}`])
    //   window.open(url.toString(), '_blank')
    // }else if(arg==='dashboard'){
    //   const url = this.router.createUrlTree(['dashboard'])
    //   window.open(url.toString(), '_blank')
    // }

    // this.router.navigate([`dashboard/${arg}`],);
  }
}
