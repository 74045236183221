import { Component, OnInit } from '@angular/core';
import { EditorService } from '../services/editor.service';
import { RazorpayService } from '../services/razorpay.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  activeLink = "Yearly";
  paymentDetails: any[] = [];
  region: string = "Global"
  constructor(private editorService: EditorService,
    private router:Router, 
    private winRef: RazorpayService ,
    private cookieServ: CookieService,
    private toastr:ToastrService) { }

  ngOnInit(): void {
    this.getRegion();
    this.getPayments();
  }
  getPayments() {
    this.editorService.getPayments().subscribe(res => {
      this.paymentDetails = res
    })
  }
  getRegion() {
    this.editorService.getRegion().subscribe((res: any) => {
      if (!!res && !!res.country) {
        this.region = res.country !== "India" ? 'Global' : 'India';
      }
    })
  }
  toFixed(num: any, fixed: any) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }
  getSubscribe(data:any) {
    // let obj = {
    //   sub_membership:this.activeLink,
    //   sub_region:this.region,
    //   sub_price_id:dataId,
    //   id:dataId
    // }
    this.editorService.checkoutPayment({plan_id:data.price_anually_razor}).subscribe(res=>{
      // window.open(res.link, '_blank');
      if(!!res && res.id){
        this.payWithRazor(res)
      }
    })
  }
  payWithRazor(data:any) {
    const options: any = {
      "key": environment.rzp_key, // Enter the Key ID generated from the Dashboard
      "amount": data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency":data.currency,
      "name": "Strell.io", //your business name
      // "description": "Test Transaction",
      "image": "../../../assets/images/strellnew.png",
      "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        "name": `${this.cookieServ.get('first_name')}  ${this.cookieServ.get('last_name')}`, //your customer's name
        "email": `${this.cookieServ.get('email')}`,
        // "contact": "9000090000" //Provide the customer's phone number for better conversion rates 
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      // "notes": {
      //   "address": "Razorpay Corporate Office"
      // },
      "theme": {
        "color": "#3399cc"
      }
    };
    // const options: any = {
    //   key: 'rzp_test_5NyqhM1eBXI3Nq',
    //   amount: 125500, // amount should be in paise format to display Rs 1255 without decimal point
    //   currency: 'USD',
    //   name: '', // company name or product name
    //   description: '',  // product description
    //   // image: './assets/logo.png', // company logo or product image
    //   order_id: "order_9A33XWu170gUtm", // order_id created by you in backend
    //   modal: {
    //     // We should prevent closing of the form when esc key is pressed.
    //     escape: false,
    //   },
    //   notes: {
    //     // include notes if any
    //   },
    //   theme: {
    //     color: '#0c238a'
    //   }
    // };
    options.handler = ((response: any,error:any) => {
      console.log(response,"respppp");
      console.log(error,"errr");
      options.response = response;
      if(!!response && response.razorpay_payment_id && response.razorpay_signature){
        this.toastr.success('Payment Successful');
        this.router.navigate(['/dashboard']);
      }
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }
  getSubscribePabbly(data:any){
    if(data.plan_name==='Gold'){
      window.open(`https://payments.pabbly.com/subscribe/666037ced6195f30c212ae44/Gold?first_name=${this.cookieServ.get('first_name')}&last_name=${this.cookieServ.get('last_name')}&email=${this.cookieServ.get('email')}&user_id=${this.cookieServ.get('uid')}`, '_blank');
    }else{
      window.open(`https://payments.pabbly.com/subscribe/6660385826fa8630b60626db/platinum?first_name=${this.cookieServ.get('first_name')}&last_name=${this.cookieServ.get('last_name')}&email=${this.cookieServ.get('email')}&user_id=${this.cookieServ.get('uid')}`, '_blank');

    }

  }
}
