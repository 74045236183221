import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  private name = new BehaviorSubject<boolean>(true);
  private id = new BehaviorSubject<any>("");

  nameKeeper = this.name.asObservable();
  private theamName$ = new Subject<any>();
  private idName$ = new Subject<any>();

  private themeBool:boolean = true
  constructor() { }


  public getThemeName(){
    return this.theamName$;
  }

  public setThemeName(value:any) {
   this.themeBool = value;
    this.theamName$.next(value);
  }

  public pingThemeName() {
    this.theamName$.next(this.themeBool);
  }
  SetName(name: boolean) {
    this.name.next(name);
  }
  public setId(value:any) {
     this.idName$.next(value);
   }
   public getId(){
    return this.idName$;
  }
}
