import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private baseUrl = environment.apiUrl;
  AUTH_TOKEN = "auth_token";
  constructor(private httpClient: HttpClient, private toastr: ToastrService, private router: Router) { }

  post(url: string, payload: any, params?: any): Observable<any> {
    let data : any 
    if(url != 'auth/register/' && url != 'auth/login/' && url != 'auth/user-forget-password/' && url != 'dashboard/myeditor_api/' && url != 'dashboard/get_keyword_data' && url != "dashboard/get_cluster_data" && url != 'dashboard/myaudit/updatecs/api/' ){
        data = { params, headers: this.getAuthHeader() }
    }
    if(params && params.error===false){
      return this.httpClient.post(this.baseUrl + url, payload,data);
    }else{
      return this.httpClient.post(this.baseUrl + url, payload,data).pipe(catchError(this.errorHandler.bind(this)));
    }
  }
  get(url: string, params?: any): Observable<any> {
    let data : any
    let x = url?.split('/')
    if(x.length != 0 && x[1] != 'new_myaudit_api' ){
      data = { params, headers: this.getAuthHeader() }
    }
    return this.httpClient.get(this.baseUrl + url,data).pipe(catchError(this.errorHandler.bind(this)));
  }
  put(url: string, payload: any, params?: any): Observable<any> {
    let data : any 
    if(url != 'auth/update-forget-password/'){
        data = { params, headers: this.getAuthHeader() }
    }
    return this.httpClient.put(this.baseUrl + url, payload,data).pipe(catchError(this.errorHandler.bind(this)));
  }
  delete(url: string, params?: any): Observable<any> {
    const data = { params, headers: this.getAuthHeader() }
    return this.httpClient.delete(this.baseUrl + url, data).pipe(catchError(this.errorHandler.bind(this)));
  }
  private errorHandler(response: any) {
    const error = response.error;
    const keys = Object.keys(error);
    const key = keys[0]
    let message = error[key];
    if (response.status === 401) {
      if ('auth_token' in localStorage) {
        this.toastr.error("Error 401 - Unauthorized Access");
        localStorage.clear();
        this.router.navigate([`login`]);
      } else {
        localStorage.clear();
        this.router.navigate([`login`]);
      }
      //auth token delete
      //redirect login page
      // this.toastr.error("Error 401 - Unauthorized Access");
      // localStorage.clear();
      // this.router.navigate([`login`])
    }else if (error[key] instanceof Array) {
      message = error[key][0];
      this.toastr.error(message);
      if(message == "Please enter a valid email address and password.."){
        this.router.navigate([`dashboard`])
      }
    }else if (response.status === 400) {
      this.toastr.error("Bad Request: The request is missing a required parameter.");
    }
    else if (key === 'isTrusted') {
      // this.toastr.error("No internet Connection");
    } else {
      message = key + ':' + message;
      this.toastr.error(message);
    }
    return throwError({ messages: message, error });
  }
  private getAuthHeader(): { [header: string]: string | string[]; } {
    return {
      Authorization: `Bearer ${localStorage.getItem(this.AUTH_TOKEN)}`,
      // 'Access-Control-Allow-Origin': '*',
      // mode:"no-cors"
    }
  }

}
