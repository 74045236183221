import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorService } from '../../shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { error } from 'console';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  forgotForm: FormGroup = this.formBuilder.group({})
  isSubmitted: boolean = false
  uid: any = ''

  constructor(
    private formBuilder: FormBuilder,
    private editorServ: EditorService,
    private toster: ToastrService,
    private cookieServ: CookieService,
    private router: Router,
  ) {
    this.uid = this.cookieServ.get("uid");
    if (this.uid && localStorage.getItem('auth_token')) {
      this.router.navigate([`dashboard`])
    } else {
      // this.router.navigate([`dashboard`])
    }

  }

  ngOnInit(): void {

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
    })
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.forgotForm.valid) {
      let Obj = {
        "email": this.forgotForm.controls["email"].value.toLowerCase(),
      }
      this.editorServ.forgotPass(Obj).subscribe((res: any) => {
        try {
          if (res.status.toString() == 'true') {
            this.toster.success(res.message)
            this.forgotForm.reset();
          }
          if (res.status.toString() == 'false') {
            this.toster.error(res.message)
            this.forgotForm.reset();
          }
        } catch (error: any) {
          this.toster.error(error.message)
        }
        this.isSubmitted = false;
      })
      // ,(err)=>{
      //   if(!err.status){
      //     this.toster.error(err.error.message)
      //     this.forgotForm.reset();

      //   }
      // });
      // API call for register
    }
  }



}
