<div>
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar itemscope
        itemtype="http://schema.org/SiteNavigationElement">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="uk-navbar-left uk-margin-medium-left">
                <a class="uk-navbar-item uk-logo" href="https://strell.io"
                    alt="strell">
                    <img src="../../assets/images/strellnew.png" alt="strell.io"
                        uk-img height="40px">
                </a>
            </div>
            <div class="d-flex align-items-center mx-4">
                <a href="https://app.strell.io/login/"><span
                        class="uk-button">Login</span></a>
                <a href="https://app.strell.io/register/"><span
                        class="uk-button uk-button-primary">Sign Up</span></a>
            </div>
        </div>
    </nav>
</div>

<div class="uk-flex-around tb-background" uk-grid>
    <div class="uk-width-2-5@m tb-log">
        <h1 class="uk-margin-xlarge-top uk-margin-remove-bottom">Glad to see you
            back on Strell</h1>
        <a href="https://app.strell.io/register/"
            class="uk-button uk-button-muted">Register Now <span
                uk-icon="arrow-right"></span></a>
    </div>
    <div class="uk-width-1-3@m">
        <div
            class="tb-login-container uk-margin-large-top uk-margin-large-bottom">
            <form [formGroup]="resetPassForm">
                <p>Set you new password</p>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" formControlName="password"
                            placeholder="Password" name="password">
                        <span class="text-danger"
                            *ngIf="isSubmitted && resetPassForm?.controls['password']?.errors?.required">
                            Password is required.
                        </span>
                    </div>
                </div>
                <div class="uk-margin-remove">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" formControlName="confirmPassword"
                            placeholder="Confirm Password" name="cpassword">
                            <span class="text-danger"
                            *ngIf="isSubmitted && resetPassForm?.controls['confirmPassword']?.errors?.required && resetPassForm?.controls['confirmPassword']?.value ===''">
                            Confirm password is required.
                        </span>
                        <span class="text-danger"
                        *ngIf="resetPassForm?.hasError('notSame') && resetPassForm?.controls['confirmPassword']?.value !=''">
                        Password and confirm password should be same.
                    </span>
                    </div>
                </div>
                <button (click)="submitForm()" class="uk-button uk-button-primary uk-margin-top">Reset
                    Now <span uk-icon="arrow-right"></span></button>
            </form>
        </div>
    </div>
</div>