import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorService } from '../../shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  uid : any = ''
  erroMessage : any = ''
  successMessage : any = ''
  loading:boolean = false;
  loginForm:FormGroup  = this.formBuilder.group({})
  isSubmitted:boolean = false;
  constructor(
      private formBuilder: FormBuilder,
      private editorServ: EditorService,
      private toster: ToastrService,
      private cookieServ: CookieService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private socialAuth:SocialAuthService
    ) {
       this.socialAuth.authState.subscribe((user) => {
        if(user && user!==null){
          localStorage.setItem('auth_token',user.idToken);
          this.editorServ.storegoogleAuth({access_token:user.idToken}).subscribe((res:any)=>{
            if(!!res && res.tokens && res.user_details){
              localStorage.setItem('auth_token',res.tokens.access);
              this.cookieServ.set('uid', res.user_details.uid );
              this.cookieServ.set('userName', res.user_details.first_name );
              this.cookieServ.set('email', res.user_details.email );
              this.cookieServ.set('plan', res.user_details.subscription_name );
              this.cookieServ.set('first_name', res.user_details.first_name );
              this.cookieServ.set('last_name', res.user_details.last_name );
              // this.toster.success("User login successfully")
              this.router.navigate([`dashboard`])
            }
          },(err:any)=>{
            this.toster.error(err.error.message)
          });
        }
      });
      // this.socialAuth.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then((accessToken:any) =>{
       
      // });
    this.uid = this.cookieServ.get("uid");
    if(this.uid && localStorage.getItem('auth_token') ){
      this.router.navigate([`dashboard`])
    }else{
      // this.router.navigate([`dashboard`])
    }

    }

    ngOnInit(): void {
        this.initForm();
        let x = this.activatedRoute
        .queryParams
        .subscribe((params: any) => {
          this.erroMessage = params?.error ? params?.error : "";
          this.successMessage = params?.success ? params?.success : "";
        });
        if(this.erroMessage != "" || this.successMessage != ""){
          this.toster.success("Your account is now active, Plz Login and enjoy!")
        }
    }

    initForm(){
      this.loginForm = this.formBuilder.group({
          // email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],          
          email: ['', [Validators.required, Validators.email,Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],          
          password:["",[Validators.required]],
      })
    }

    submitForm(){
      this.isSubmitted = true;
      if(this.loginForm.valid){
        let Obj = {
          "email": this.loginForm.controls["email"].value.toLowerCase(),
          "password": this.loginForm.controls["password"].value,
      }
      this.loading = true;
        this.editorServ.login(Obj).subscribe((res:any)=>{
          // try {
            if(res.user_details){
              if(this.erroMessage != ""){
                this.toster.error(this.erroMessage)
              }
              if(this.successMessage != ""){
                this.toster.success(this.successMessage)
              }

              localStorage.setItem('auth_token',res.tokens.access);
              this.cookieServ.set('uid', res.user_details.uid );
              this.cookieServ.set('userName', res.user_details.first_name );
              this.cookieServ.set('email', res.user_details.email );
              this.cookieServ.set('plan', res.user_details.subscription_name );
              this.cookieServ.set('first_name', res.user_details.first_name );
              this.cookieServ.set('last_name', res.user_details.last_name );
              // this.toster.success("User login successfully")
              this.router.navigate([`dashboard`])
              this.loginForm.reset();
              this.loading = false;
            }
            if(res.non_field_errors){
              this.toster.error(res.non_field_errors[0])
              this.loginForm.reset();
              this.loading = false;
            }
          // } catch (error:any) {
          //   this.toster.error(error.message)
          //   this.loading = false;
          // }
        },(err)=>{
          this.toster.error(err.error[0])
          this.loading = false;
        });
        this.isSubmitted = false;
        // API call for register
      }
    }
}
