import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEditorModule } from 'ngx-editor';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { CustomGaugeComponent } from './custom-gauge/custom-gauge.component';
import { IndexComponent } from '../strell/index/index.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SumoCodeComponent } from '../strell/sumo-code/sumo-code.component';
import { TeamComponent } from '../strell/team/team.component';
import { SettingComponent } from '../strell/setting/setting.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PaymentsComponent } from './payments/payments.component';
import { ErrorPageComponent } from './error-page/error-page.component';



@NgModule({
  declarations: [
    ModalComponent,
    CustomGaugeComponent,
    HeaderComponent,
    SidebarComponent,
    ErrorPageComponent,
    // PaymentsComponent
    // SumoCodeComponent,
    // TeamComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    NgbPaginationModule,
    NgxEditorModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports:[
    CustomGaugeComponent,
    SidebarComponent
  ]
})
export class SharedModule { }
