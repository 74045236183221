import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeywordData } from '../classes/keywordData';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';
import { Subject, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { webSocket } from 'rxjs/webSocket';
// import { OAuth2Client } from 'google-auth-library';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  // private baseUrl = environment.apiUrl;
  // private clientId = '696649230161-q8tf4ln6vit0vsqikltbq24rqu0polq2.apps.googleusercontent.com';
  // private redirectUri = 'http://localhost:4200';
  // // private redirectUri = 'https://angular.strell.io';
  // private scope = "https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/userinfo.email"
  // private CLIENT_SECRET ='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'
  // private setFlag$ = new Subject<any>();
  // private errorFlag = new Subject<any>();
  // private settingData$ = new Subject<any>();

  private baseUrl = environment.apiUrl;
  private clientId = '1066248667795-v8jjep9hvs4i9ttmcdnbjvj9ecb1un9h.apps.googleusercontent.com';
  // private redirectUri = 'http://localhost:4200/dashboard/integration';
  private redirectUri = environment.redirectUrl;
  private scope = "https://www.googleapis.com/auth/webmasters.readonly"
  private CLIENT_SECRET ='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';
  private api_key = 'AIzaSyD-rxeu5xQKQS7j_tSVWnyzoVYwcMRB9-A'
  private setFlag$ = new Subject<any>();
  private errorFlag = new Subject<any>();
  private settingData$ = new Subject<any>();
  
  constructor(private httpService:HttpService,private router:Router,private http:HttpClient, private toastr : ToastrService) { 

  }

  authorizes(id?:any) {
    const authEndpoint = 'https://accounts.google.com/o/oauth2/auth';
    const queryParams : any = new URLSearchParams({
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      state:id,
      response_type: 'code',
      scope: this.scope,
      access_type: 'offline',
    });
    if(!id || id==undefined){
      delete queryParams.state;
    }
    return `${authEndpoint}?${queryParams.toString()}`;
  }
  getAuthCode(){
    let body = new URLSearchParams();
    body.set('client_id', this.clientId);
    body.set('redirect_uri',this.redirectUri);
    body.set('response_type', 'code');
    body.set('scope', this.scope);

    // body.set('client_secret', this.CLIENT_SECRET);
    // body.set('redirect_uri', this.redirectUri);
    body.set('access_type', 'offline');

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
   return this.http
    .post('https://accounts.google.com/o/oauth2/auth', body.toString(), options)
  }
  
  getToken(code:any) {
    let body = new URLSearchParams();
    body.set('code', code);
    body.set('client_id', this.clientId);
    body.set('client_secret', this.CLIENT_SECRET);
    body.set('redirect_uri', this.redirectUri);
    body.set('grant_type', 'authorization_code');
    body.set('access_type', 'offline');
    body.set('approval_prompt', 'force');
    body.set('prompt', 'consent');

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
   return this.http
    .post('https://accounts.google.com/o/oauth2/token', body.toString(), options)
  }
  getNewToken() {
    let body = new URLSearchParams();
    body.set('client_id', this.clientId);
    body.set('client_secret', this.CLIENT_SECRET);
    body.set('refresh_token', JSON.parse(localStorage.getItem('google_refresh_token') || ''));
    body.set('grant_type', 'refresh_token');
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
   return this.http
    .post('https://accounts.google.com/o/oauth2/token', body.toString(), options)
  }
  getSites(token:string){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }
    return this.http
    .get("https://www.googleapis.com/webmasters/v3/sites",header);
  }
  getSiteData(site:string,token:string,payload:any){
    var header = {
      headers: new HttpHeaders({
        'Authorization':`Bearer ${token}`,
      })
    }
    let newSite:any = site.split('');
    if(newSite[newSite.length - 1]==='/'){
      newSite.pop()
    }
    const codec = new HttpUrlEncodingCodec();
      newSite = newSite.join('');
      const encodedValue = encodeURIComponent(newSite);
    return this.http
    .post(`https://www.googleapis.com/webmasters/v3/sites/${encodedValue}/searchAnalytics/query`,payload,header);
  }
  public setErrorFlag(value:any){
    this.errorFlag.next(value)
  }
  public getErrorFlag(){
    return this.errorFlag;
  }
  public getFlag(){
     return this.setFlag$;
   }
 
   public setFlag(value:any) {
     this.setFlag$.next(value);
   }
 
  public getSettingData(){
     return this.settingData$;
   }
 
   public sendLocalSettingData(value:any) {
     this.settingData$.next(value);
   }
   storegoogleAuth(payload:any){
    return this.http.post(`${environment.apiUrl}auth/google-auth/`,payload);
   }
   storeGsc(payload:any){
    return this.httpService.post('dashboard/store_gsc',payload);
   }
   getGsc(){
    return this.httpService.get('dashboard/store_gsc');
   }
   getAuthToken(payload:any){
    return this.httpService.post('dashboard/authenticate_account', payload)
   }
   postKeyword(payload:any){
    return this.httpService.post('dashboard/add_lowfruit_data', payload)
   }
   saveEditorSettings(payload:any){
    return this.httpService.post('dashboard/save_lowfruit_setting_data', payload)
   }
   getLckrData(payload:any){
    return this.httpService.post('dashboard/get_lowfruit_data', payload)
   }
   getListData(payload:any){
    return this.httpService.post('dashboard/get_list_keyword_by_user', payload)
   }
  getListKeywordData(payload:any){
    return this.httpService.post('dashboard/get_list_keyword_by_user',payload)
  }
  addNewList(payload:any){
    return this.httpService.post('dashboard/add_lowfruit_data',payload)
  }
  deleteListName(payload:any){
    return this.httpService.post('dashboard/update_delete_list_name',payload)
  }
  getTagData(payload:any){
    return this.httpService.post('dashboard/get_alltags',payload)
  }
  addLckrTag(payload:any){
    return this.httpService.post('dashboard/add_lckrtag',payload)
  }
  updateLckrTag(payload:any){
    return this.httpService.post('dashboard/update_lckrtags',payload)
  }
  deleteLckrKeywords(payload:any){
    return this.httpService.post('dashboard/delete_lckr_data',payload)
  }
  postEditorData(payload: KeywordData) {
    return this.httpService.post('editor/', payload)
  }
  postAuditKeyword(payload: KeywordData) {
    return this.httpService.post('audit/', payload)
  }
  getLowfruitDataByID(payload:any){
    return this.httpService.post('dashboard/lowfruit_data_by_lid',payload)
  }
  addKeyWordData(payload?:any) {
    return this.httpService.post(`dashboard/add_keyword_data`,payload)
  }
  getKeyWordDataByKid(id:any) {
    return this.httpService.post('dashboard/keyword_data_by_kid',id)
  } 
  getKeyWordData(payload:any) {
    return this.httpService.post(`dashboard/get_keyword_data`,payload)
  } 
  saveHideKeyword(payload:any) {
    return this.httpService.post('dashboard/save_hide_keyword',payload)
  } 
  gettopicdata(payload:any){
    // return this.http.post('http://127.0.0.1:4000/gettopic/', payload)
    return this.httpService.post('gettopic', payload)
  }
  exportExcel(payload:any){
    return this.http.post('https://development.strell.io/export_excel', payload).pipe(catchError(this.errorHandler2.bind(this)));
    // return this.http.post('http://127.0.0.1:4000/export_excel', payload).pipe(catchError(this.errorHandler2.bind(this)));
    // return this.httpService.post('export_excel', payload)
  }
  exportSelectedExcel(payload:any){
    return this.http.post('https://development.strell.io/export_selected_excel', payload).pipe(catchError(this.errorHandler2.bind(this)));
    // return this.httpService.post('export_selected_excel', payload)
  }
  exportClusterExcel(payload:any){
    return this.http.post('https://development.strell.io/export_cluster_excel', payload).pipe(catchError(this.errorHandler2.bind(this)));
    // return this.httpService.post('export_cluster_excel', payload)
  }
  getGlobalSettingData(payload:any) {
    return this.httpService.post('dashboard/get_global_setting_data',payload)
  }
  addExcludeKeywords(payload:any) {
    return this.httpService.post('dashboard/save_exclude_data/',payload)
  }
  addIncludeKeywords(payload:any) {
    return this.httpService.post('dashboard/save_include_data/',payload)
  }
  removeIncludeKeywords(payload:any) {
    return this.httpService.post('dashboard/remove_include_data/',payload)
  }
  addContentEditorData(payload:any) {
    return this.httpService.post('dashboard/content_editors_api/',payload)
  }
  getAllContentEditorData(payload:any) {
    return this.httpService.post('dashboard/content_editors_api/',payload);
  }
  saveDashboardSettings(payload:any){
    return this.httpService.post('dashboard/save_lowfruit_setting_data/',payload);
  }
  getSharedData(payload:any){
    return this.httpService.post('dashboard/sharing_api/',payload);
  }
  getEditorData(payload:any) {
    return this.httpService.post('dashboard/myeditor_api/',payload);

  }
  saveClusterData(payload:any){
    return this.httpService.post('dashboard/save_cluster_data',payload);
  }
  getSavedClusterData(payload:any){
    return this.httpService.post('dashboard/get_cluster_data',payload);
  }
  exportSeprateDataExcel(payload:any){
    return this.http.post('https://development.strell.io/export_separate_data', payload).pipe(catchError(this.errorHandler2.bind(this)));
    // return this.httpService.post('export_separate_data',payload);
  }

  saveGlobalSettingTrue(payload:any){
    return this.httpService.post('dashboard/update_setting_type',payload);
  }
  addListName(payload:any){
    return this.httpService.post('dashboard/add_list_name',payload);
  }
  getListName(payload:any){
    return this.httpService.post('dashboard/get_list_name',payload);
  }
  addListData(payload:any){
    return this.httpService.post('dashboard/add_get_list_data',payload);
  }
  addLowFruitList(payload:any){
    return this.httpService.post('dashboard/add_lowfruit_data',payload);
  }
  addDataInList(payload:any){
    return this.httpService.post('dashboard/add_custum_keyword_inlist',payload);
  }
  addContentTag(payload:any){
    return this.httpService.post('dashboard/add_lckrtag',payload);
  }
  saveTagByAid(payload:any){
    return this.httpService.post('dashboard/add_content_tag_by_aid',payload);
  }
  removeTabById(payload:any){
    return this.httpService.post('dashboard/remove_tag_by_aid',payload);
  }
  getContentTagByUser(payload:any){
    return this.httpService.post('dashboard/get_content_user_tags',payload);
  }
  deleteReportByAid(payload:any){
    return this.httpService.post('dashboard/remove_editor_by_aid',payload);
  }
  deleteTagByAid(payload:any){
    return this.httpService.post('dashboard/delete_tag_by_aid',payload);
  }

  // postAudit(payload:any){
  //   return this.httpService.post('dashboard/get_cluster_data',payload);
  // }
  getContentDataVieUrl(payload:any){
    return this.httpService.post('dashboard/get_editor_data_via_url',payload);
  }
  getAndPostAudit(payload:any){
    return this.httpService.post('dashboard/new_audits_api/',payload);
  }
  getAuditReport(id:any){
    return this.httpService.get(`dashboard/new_myaudit_api/${id}/`);
  }
  saveContent(id:string,payload:any){
    return this.httpService.post(`dashboard/content_editor_api/save/${id}/`,payload);
  }
  updateTerms(payload:any){
    return this.httpService.post("dashboard/term-content-editor-api/",payload)
  }
  applyCompititors(payLoad:any){
    return this.httpService.post("dashboard/content_editor_api/myeditor_competitors/",payLoad);
  }
  saveStructure(payLoad:any){
    return this.httpService.post("dashboard/ss-content-editor-api/",payLoad);
  }
  completeEditor(payLoad:any,aid:string){
    return this.httpService.post(`dashboard/content_editor_api/actions/${aid}/`,payLoad);
  }
  completeAudit(payLoad:any,aid:string){
    return this.httpService.post(`dashboard/new_audit/actions/api/${aid}/`,payLoad);
  }
  hardRefreshEditor(payLoad:any,aid:any){
    return this.httpService.post(``,payLoad);
  }
  saveNotes(payload:any){
    return this.httpService.post(`dashboard/update/content-editor/note/`,payload);
  }
 saveAuditCompititor(payload:any){
  return this.httpService.post('dashboard/new_myaudit_competitors/',payload);
 }
 softRefresh(payload:any,aid:any){
  return this.httpService.post(`dashboard/new_audit/actions/api/${aid}/`,payload)
 }
 appSumoRegistration(payload:any){
  return this.httpService.post('appsumo_registration',payload)
 }
 registration(payload:any){
  return this.httpService.post('auth/register/',payload)
 }
 login(payload:any){
  return this.httpService.post('auth/login/',payload,{error:false})
 }
 forgotPass(payload:any){
  return this.httpService.post('auth/user-forget-password/',payload)
 }
 updateForgotPass(payload:any){
  return this.httpService.put('auth/update-forget-password/',payload)
 }
 getUserprofile(payload:any){
  return this.httpService.post('dashboard/get_user_details',payload);
 }
 resetPass(payload:any){
  return this.httpService.post('dashboard/reset_password',payload);
 }
 applyAppSumoCode(payload:any){
  return this.httpService.post('dashboard/get_appsumo_redeem_appsumo',payload);
 }
 updateCredit(payload:any){
  return this.httpService.post('dashboard/updateCredit',payload);
 }
 createAndGetTeam(payload:any){
  return this.httpService.post('dashboard/create_and_get_team',payload);
 }
 deleteTeam(payload:any){
  return this.httpService.post('dashboard/delete_full_team',payload);
 }
 addTeamMember(payload:any){
  return this.httpService.post('dashboard/add_team_member',payload);
 }
 removeTeamMember(payload:any){
  return this.httpService.post('dashboard/remove_team_user',payload);
 }
 saveAuditScore(payload:any){
  return this.httpService.post('dashboard/myaudit/updatecs/api/',payload);
 }
 deleteListKeywords(payload:any){
  return this.httpService.post('dashboard/delete_list_keyword_by_lid',payload);
 }
 postBaseOutline(payload:any){
  return this.httpService.post('dashboard/saveBaseOutline',payload);
 }
 getIntregratedAccount(payload:any){
  return this.httpService.post('dashboard/getIntegrateAccount',payload);
 }
 addIntregratedAccount(payload:any){
  return this.httpService.post('dashboard/addIntegrateAccount',payload);
 }
 deleteIntregratedAccount(payload:any){
  return this.httpService.post('dashboard/deleteWordPressAccount',payload);
 }
 seveGlobalSetting(payload:any){
  return this.httpService.post('dashboard/saveDefaultSetting',payload);
 }
 saveInternalLinks(id:string,payload:any){
  return this.httpService.post(`dashboard/internal-linking?aid=${id}`,payload);
 }
 getnternalLinks(id:string){
  return this.httpService.get(`dashboard/internal-linking?aid=${id}`);
 }
 getAiContent(payload:any){
  return this.httpService.post('dashboard/strell-ai',payload);
 }
 getPayments(){
  return this.httpService.get('payment/');
 }
 checkoutPayment(payload:any){
  return this.httpService.post('payment/razor_sub_create',payload);
 }
 getRegion(){
  return this.http.get("https://pro.ip-api.com/json/?fields=61439&key=plCwG987PiLwtLA")
 }
 checkCrediantial(payload : any){
  // return this.http.post('http://127.0.0.1:4000/check_account_crediantial',payload).pipe(catchError(this.errorHandler.bind(this)))
  return this.http.post(this.baseUrl +'check_account_crediantial',payload).pipe(catchError(this.errorHandler.bind(this)))
 }
 getSocketRes(id:string){
  return webSocket(`${environment.socketUrl}socket/content-editor/${id}`);
}

 addContent(payload:any) {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(payload.username + ':' + payload.password)
  });

  const authData = {
    title:payload.title,
    content:payload.content,
  };
  return this.http.post(`https://${payload.website}/wp-json/wp/v2/posts`, authData, { headers }).pipe(catchError(this.errorHandler.bind(this)));;
}
private errorHandler(response: any) {
  const error = response.error;
  const keys = Object.keys(error);
  const key = keys[0]
  let message = error[key];
  this.setErrorFlag(true)
  if(response?.error?.message){
    this.toastr.error(response?.error?.message);
  }else if(response.status === 401) {
    //auth token delete
    //redirect login page
    this.toastr.error("The WordPress site is NOT connected due to incorrect credentials.");
  }else if (response.status === 400) {
    this.toastr.error("Bad Request: The request is missing a required parameter.");
  }else{
    this.toastr.error("The WordPress site is NOT connected due to incorrect credentials.");
  }
  return throwError({ messages: message, error });
}

private errorHandler2(response: any) {
  const error = response.error;
  const keys = Object.keys(error);
  const key = keys[0]
  let message = error[key];
  if (response.status === 401) {
    if ('auth_token' in localStorage) {
      this.toastr.error("Error 401 - Unauthorized Access");
      localStorage.clear();
      this.router.navigate([`login`]);
    } else {
      localStorage.clear();
      this.router.navigate([`login`]);
    }
    //auth token delete
    //redirect login page
    // this.toastr.error("Error 401 - Unauthorized Access");
    // localStorage.clear();
    // this.router.navigate([`login`])
  }else if (error[key] instanceof Array) {
    message = error[key][0];
    this.toastr.error(message);
    if(message == "Please enter a valid email address and password.."){
      this.router.navigate([`dashboard`])
    }
  }else if (response.status === 400) {
    this.toastr.error("Bad Request: The request is missing a required parameter.");
  }
  else if (key === 'isTrusted') {
    // this.toastr.error("No internet Connection");
  } else {
    message = key + ':' + message;
    this.toastr.error(message);
  }
  return throwError({ messages: message, error });
}
}
