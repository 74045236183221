import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './auth/register/register.component';
import { IndexComponent } from './strell/index/index.component';
import { AppsumoRegisterComponent } from './auth/appsumo-register/appsumo-register.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ResetComponent } from './auth/reset/reset.component';
import { PaymentsComponent } from './shared/payments/payments.component';
import { RedirectComponentComponent } from './strell/redirect-component/redirect-component.component';
import { PreloadAllModules } from '@angular/router';
import { ErrorPageComponent } from './shared/error-page/error-page.component';

const routes: Routes = [
  // {path: '404', component: ErrorPageComponent},
  // {path: '**', redirectTo: '/404'}
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // {path: "**",redirectTo:"login"},
  {path:'payment/success',component:RedirectComponentComponent,data: {payment_status: 1}},
  {path:'payment/cancel',component:RedirectComponentComponent,data: {payment_status: 2}},
  { path: 'dashboard', loadChildren: () => import('./strell/strell.module').then(m => m.StrellModule) },
  { path: 'dashboard/lckr', loadChildren: () => import('./keyword-finder/keyword-finder.module').then(m => m.KeywordFinderModule) },
  { path: 'lckr/reports', loadChildren: () => import('./lckr/lckr.module').then(m => m.LckrModule) },
  { path: 'dashboard/content-editor', loadChildren: () => import('./content-editor/content-editor.module').then(m => m.ContentEditorModule) },
  { path: 'dashboard/audit-report', loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule) },
  // { path: 'appsumo', component: AppsumoRegisterComponent, title: "Apply Code | Strell.io" },
  { path: 'register', component: RegisterComponent, title: "Register | Strell.io" },
  { path: 'login', component: LoginComponent, title: "Login | Strell.io" },
  { path: 'forgot', component: ForgotComponent, title: "Forgot | Strell.io" },
  { path: 'reset/:id', component: ResetComponent, title: "Reset | Strell.io" },
  { path: 'payments', component: PaymentsComponent, title: "Payments | Strell.io" },
  {path: '**', component:ErrorPageComponent}

  // { path: 'index', component:IndexComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
