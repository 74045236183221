<div>
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar itemscope itemtype="http://schema.org/SiteNavigationElement">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="uk-navbar-left uk-margin-medium-left">
                <a class="uk-navbar-item uk-logo" href="https://strell.io" alt="strell">
                    <img src="../../assets/images/strellnew.png" alt="strell.io" uk-img height="40px">
                </a>
            </div>
            <div class="d-flex align-items-center mx-4">
                <a href="https://app.strell.io/login/"><span class="uk-button">Login</span></a>
                <a href="https://app.strell.io/register/"><span class="uk-button uk-button-primary">Sign Up</span></a>
            </div>
        </div>
    </nav>
</div>

<div class="uk-flex-around tb-background" uk-grid>
    <div class="uk-width-2-5@m tb-log">
        <h1 class="uk-margin-xlarge-top uk-margin-remove-bottom">Facing trouble with your account?</h1>
        <p class="uk-margin-small">Reset your password by generating a activation link and that will be sent to your registered email.</p>
        <a href="https://app.strell.io/register/" style="color: white;" class="uk-button uk-button-secondary">Register Now <span uk-icon="arrow-right"></span></a>
    </div>
    <div class="uk-width-1-3@m">
        <div class="tb-login-container uk-margin-large-top uk-margin-large-bottom">
            <form [formGroup]="forgotForm" >
                <p>Forgot your account password?</p>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: mail"></span>
                        <input class="uk-input" type="email" placeholder="Email Address" formControlName="email" name="username">
                        <span class="text-danger"
                        *ngIf="isSubmitted && forgotForm?.controls['email']?.errors?.required">
                        Email is required.
                    </span>
                    <span class="text-danger" *ngIf="isSubmitted && forgotForm?.controls['email']?.errors?.email">Email must be a valid email address
                    </span>
                    </div>
                </div>
                <div class="uk-text-right forgot-pass"><a href="https://app.strell.io/login/" class="uk-text-small">Back to Login?</a></div>
                <button (click)="submitForm()" class="uk-button uk-button-primary uk-margin-small-top" [disabled]="forgotForm?.controls['email']?.errors?.email">Reset Now <span uk-icon="arrow-right"></span></button>
            </form>
        </div>
    </div>
</div>