<div>
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar itemscope
        itemtype="http://schema.org/SiteNavigationElement">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="uk-navbar-left uk-margin-medium-left">
                <a class="uk-navbar-item uk-logo" href="https://strell.io" alt="strell">
                    <img src="../../assets/images/strellnew.png" alt="strell.io" uk-img height="40px">
                </a>
            </div>
            <div class="d-flex align-items-center mx-4">
                <a href="https://app.strell.io/login/"><span class="uk-button">Login</span></a>
                <a href="https://app.strell.io/register/"><span class="uk-button uk-button-primary">Sign
                        Up</span></a>
            </div>
        </div>
    </nav>
</div>


<div class="uk-flex-center" uk-grid>
    <div class="uk-width-2-5@m">
        <div class="st-login-container uk-margin-large-top uk-margin-large-bottom">
            <form [formGroup]="loginForm">
                <p>Login to your account</p>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: mail"></span>
                        <input class="uk-input" type="email" placeholder="Email Address" formControlName="email">
                        <span class="text-danger" *ngIf="isSubmitted && loginForm?.controls['email']?.errors?.required">
                            Email is required.
                        </span>
                        <span class="text-danger"
                            *ngIf="isSubmitted && loginForm?.controls['email']?.errors?.email">Email must be a valid
                            email address
                        </span>
                    </div>
                </div>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" placeholder="Password" formControlName="password">
                        <span class="text-danger"
                            *ngIf="isSubmitted && loginForm?.controls['password']?.errors?.required">
                            Password is required.
                        </span>
                    </div>
                    <div class="uk-text-right forgot-pass"><a href="https://app.strell.io/forgot/"
                            class="uk-text-small">Forgot Password?</a></div>
                </div>
                <button (click)="submitForm()" class="uk-button uk-button-primary">Login Now <span
                        uk-icon="arrow-right"></span></button>
            </form>
            <div class="my-4">
                <!-- <h6 class=" text-uppercase">Text content</h6> -->
                <!-- Gradient divider -->
                <hr data-content="OR" class="hr-text">
            </div>
            <div class="google_signin mt-2 d-flex justify-content-center align-items-center">
                <asl-google-signin-button #googleBtnRef  type='standard' shape="circle" size='large' theme="filled_black"  text="signup_with"></asl-google-signin-button>
            </div>
            <!-- <button (click)="loginWithGoogle()" class="uk-button uk-button-primary mt-2">Login with google </button> -->
        </div>
    </div>
</div>

<ng-template #customLoadingTemplate>
    <div class="custom-class">
    </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"
    [template]="customLoadingTemplate"></ngx-loading>